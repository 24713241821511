var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:"},[_c('div',{staticClass:"tw-flex tw-w-full tw-justify-between tw-items-center"},[_c('icons',{attrs:{"name":"arrow-back"}}),_c('base-button',{attrs:{"button-text":"Create "},on:{"click":function($event){return _vm.$router.push({name: 'CreateDiscount'})}}})],1),_c('div',{staticClass:"company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap"},[_c('h6',{staticClass:"header"},[_vm._v("All Discount")]),_c('div',{staticClass:"mr-2 tw-mt-5 lg:tw-mt-0 ",staticStyle:{"border-radius":"10px","border":"1px solid rgba(79, 79, 79, 0.08)"}},[_c('v-text-field',{staticClass:"p-0",attrs:{"placeholder":"Search for discount","solo":"","flat":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"ml-5",attrs:{"color":_vm.color}},[_vm._v(" mdi-magnify ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v(" mdi-filter-variant ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mt-4 mx-1 table",attrs:{"headers":_vm.headers,"mobile-breakpoint":0,"search":_vm.search,"item-key":"id","single-select":false,"height":"available","hide-default-footer":_vm.discounts.length <= 7,"items":_vm.discounts,"no-data-text":_vm.not_found,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header.name",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Title")])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.name))])]}},{key:"header.type",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Type")])]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.type))])]}},{key:"header.unit",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Unit (s)")])]},proxy:true},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.unit))])]}},{key:"header.unitAmount",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Unit Amount")])]},proxy:true},{key:"item.unitAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.unitAmount))])]}},{key:"header.startDate",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Start Date")])]},proxy:true},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(_vm.getDate(item.startDate)))])]}},{key:"header.endDate",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("End Date")])]},proxy:true},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(_vm.getDate(item.endDate)))])]}},{key:"header.additionalIncentive",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Additional Incentive")])]},proxy:true},{key:"item.additionalIncentive",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.additionalIncentive))])]}},{key:"header.additionalIncentiveUnitAmount",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("AI Unit Amount")])]},proxy:true},{key:"item.additionalIncentiveUnitAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.additionalIncentiveUnitAmount))])]}},{key:"header.totalBookings",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Total Bookings")])]},proxy:true},{key:"item.totalBookings",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.totalBookings))])]}},{key:"header.createdBy",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Created By")])]},proxy:true},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.createdByFirstName)+" "+_vm._s(item.createdByLastName))])]}},{key:"header.createdAt",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Date Created")])]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(_vm.getDate(item.createdAt)))])]}},{key:"header.status",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Status")])]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status.toLowerCase() === 'active')?_c('v-chip',{attrs:{"color":"#00C48C69"}},[_c('span',{staticClass:"td-style",staticStyle:{"color":"#0F674E"}},[_vm._v(" Active ")])]):_vm._e(),(item.status.toLowerCase() === 'inactive')?_c('v-chip',{attrs:{"color":"#C8486759"}},[_c('span',{staticClass:"td-style",staticStyle:{"color":"#71011C"}},[_vm._v(" Inactive ")])]):_vm._e(),(item.status.toLowerCase() === 'pending')?_c('v-chip',{attrs:{"color":"rgba(147, 147, 147, 0.41)"}},[_c('span',{staticClass:"td-style",staticStyle:{"color":"#4B4B4B"}},[_vm._v(" Pending ")])]):_vm._e()]}},{key:"header.dateCreated",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Date Created")])]},proxy:true},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(_vm.getDate(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',{staticClass:"d-flex justify-center"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true)},[_c('v-list',[(item.status === 'ACTIVE')?_c('v-list-item',{on:{"click":function($event){return _vm.deactivateActiveDiscount(item)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer menu-text",staticStyle:{"color":"black"}},[_vm._v(" Deactivate ")])],1):_vm._e()],1)],1)]}}],null,true)})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }