<template>
<div class="tw-flex tw-w-full tw-flex-col lg:">
  <div class="tw-flex tw-w-full tw-justify-between tw-items-center">
    <icons name="arrow-back" />
    <base-button button-text="Create " @click="$router.push({name: 'CreateDiscount'})" />
  </div>

  <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
    <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
      <h6 class="header">All Discount</h6>
      <div
          class="mr-2 tw-mt-5 lg:tw-mt-0 "
          style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)"
      >
        <v-text-field
            placeholder="Search for discount"
            class="p-0"
            solo
            flat
            hide-details
            v-model="search"
        >
          <template #prepend>
            <v-icon :color="color" class="ml-5">
              mdi-magnify
            </v-icon>
          </template>
          <template #append>
            <v-icon :color="color">
              mdi-filter-variant
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          :search="search"
          item-key="id"
          :single-select="false"
          height="available"
          :hide-default-footer="discounts.length <= 7"
          :items="discounts"
          :no-data-text="not_found"
          class="mt-4 mx-1 table"
          :loading="loading"


      >
        <template #header.name>
          <span class="status">Title</span>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span class="td-style">{{ item.name }}</span>
        </template>

        <template #header.type>
          <span class="status">Type</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span class="td-style">{{ item.type }}</span>
        </template>
        <template #header.unit>
          <span class="status">Unit (s)</span>
        </template>
        <template v-slot:[`item.unit`]="{ item }">
          <span class="td-style">{{item.unit }}</span>
        </template>
        <template #header.unitAmount>
          <span class="status">Unit Amount</span>
        </template>
        <template v-slot:[`item.unitAmount`]="{ item }">
          <span class="td-style">{{item.unitAmount }}</span>
        </template>
        <template #header.startDate>
          <span class="status">Start Date</span>
        </template>
        <template v-slot:[`item.startDate`]="{ item }">
          <span class="td-style">{{getDate(item.startDate) }}</span>
        </template>
        <template #header.endDate>
          <span class="status">End Date</span>
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          <span class="td-style">{{getDate(item.endDate) }}</span>
        </template>
        <template #header.additionalIncentive>
          <span class="status">Additional Incentive</span>
        </template>
        <template v-slot:[`item.additionalIncentive`]="{ item }">
          <span class="td-style">{{item.additionalIncentive }}</span>
        </template>
        <template #header.additionalIncentiveUnitAmount>
          <span class="status">AI Unit Amount</span>
        </template>
        <template v-slot:[`item.additionalIncentiveUnitAmount`]="{ item }">
          <span class="td-style">{{item.additionalIncentiveUnitAmount }}</span>
        </template>
        <template #header.totalBookings>
          <span class="status">Total Bookings</span>
        </template>
        <template v-slot:[`item.totalBookings`]="{ item }">
          <span class="td-style">{{item.totalBookings }}</span>
        </template>
        <template #header.createdBy>
          <span class="status">Created By</span>
        </template>
        <template v-slot:[`item.createdBy`]="{ item }">
          <span class="td-style">{{item.createdByFirstName }} {{item.createdByLastName}}</span>
        </template>
        <template #header.createdAt>
          <span class="status">Date Created</span>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span class="td-style">{{getDate(item.createdAt) }}</span>
        </template>
        <template #header.status>
          <span class="status">Status</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip v-if="item.status.toLowerCase() === 'active'" color="#00C48C69">
                <span class="td-style" style="color: #0F674E">
                  Active
                </span>
          </v-chip>
          <v-chip v-if="item.status.toLowerCase() === 'inactive'" color="#C8486759">
                <span class="td-style" style="color: #71011C">
                  Inactive
                </span>
          </v-chip>
          <v-chip v-if="item.status.toLowerCase() === 'pending'" color="rgba(147, 147, 147, 0.41)">
                <span class="td-style" style="color: #4B4B4B">
                  Pending
                </span>
          </v-chip>
        </template>
        <template #header.dateCreated>
          <span class="status">Date Created</span>
        </template>
        <template v-slot:[`item.dateCreated`]="{ item }">
          <span class="td-style">{{getDate(item.createdAt) }}</span>
        </template>
        <template v-slot:[`item.actions`] ="{item}">
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <td class="d-flex justify-center">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="color">mdi-dots-horizontal</v-icon>
                </v-btn>
              </td>
            </template>
            <v-list>
              <v-list-item class="" v-if="item.status === 'ACTIVE'" @click="deactivateActiveDiscount(item)">
                <v-list-item-title
                    style="color: black"
                    class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                  Deactivate
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </div>
</div>
</template>

<script>
import Icons from "@/components/reuseables/Icons";
import BaseButton from "@/components/reuseables/BaseButton.vue";
import dayjs from "dayjs";
import {allDiscounts, deactivateDiscount} from "../../../services/api/APIService";
export default {
  name: "AllDiscountView",
  components: {BaseButton, Icons},
  data(){
    return{
      color: "#004AAD",
      search : "",
      discounts:[],
      not_found : "Discount will show here",
      headers: [
        { text: "", value: "name" },
        { text: "", value: "type" },
        { text: "", value: "unit" },
        { text: "", value: "unitAmount" },
        { text: "", value: "startDate" },
        { text: "", value: "endDate" },
        { text: "", value: "additionalIncentive" },
        { text: "", value: "additionalIncentiveUnitAmount" },
        { text: "", value: "totalBookings" },
        { text: "", value: "createdBy" },
        {text: "",value: "createdAt"},
        { text: "", value: "status" },
        { text: "", value: "actions", align: "end" },
      ],
      loading : false,
      showModal : false,
      modalLoading : false,
      modalTitle: "",
      modalDesc : " ",
      transportCompanyId:null,
      selectedPost:{}

    }
  },

  methods:{
    handleModal(item){
      if (item) {
        this.selectedPost = item
      }
      this.showModal = !this.showModal
    },

   async getAllDiscounts(){
      this.loading = true
      await allDiscounts().then(res =>{
        this.discounts = res.data
        this.discounts.sort(function (a, b) {
          return dayjs(b.createdAt) - dayjs(a.createdAt);
        });
        this.discounts.forEach((post, index) => (post.sno = index + 1));
        this.loading = false
      }).catch(err => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false
        })
        this.loading = false
      })
    },
    async deactivateActiveDiscount(item){
      let data = {}
      data.id = item.id
      data.status = 'INACTIVE'
      await deactivateDiscount(data).then(() =>{
        this.getAllDiscounts()
      }).catch(err => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false
        })
        this.loading = false
      })
    },
    routeToEditPost(item){
      // sessionStorage.setItem("post", JSON.stringify(item))
      this.$store.dispatch("marketing/setSelectedBlogPost",item)
      this.$router.push({name: 'EditBlogPost',params:{title: item.postTitle}})
    },

    getDate(date){
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      if (dayjs(date).toDate().toDateString()=== today.toDateString()) {
        return "Today";
      } else if (dayjs(date).toDate().toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        return dayjs(date).toDate().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
      }
    },
  },
  mounted() {
    this.getAllDiscounts()
  },
  async created() {
    await this.getAllDiscounts()
  }
}
</script>

<style scoped lang="scss">
.company-dashboard{
  display: flex;
  background: #FFFFFF;
  width: 100%;
  min-height: 70vh;
  max-height: available;
  border-radius: 10px;
  flex-direction: column;
}
.header{
  font-family: 'Inter', sans-serif ;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #004AAD;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.table{
  width: 100%;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  text-transform: capitalize;

}
.company-logo{
  height: 100px;
  width: 100px;
  border-radius: 10px;
  object-fit: contain;
}

.no-image{
  display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
</style>